<template>
  <div v-if="canAccess('filiales_read')" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing"/>

        <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                   v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                   paginatorPosition="bottom" :rows="10">
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Gestionar Filiales</h5>
              <div>
                 <span class="p-input-icon-left p-ml-3">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global']" placeholder="Search"/>
                 </span>
                <Button v-if="canAccess('filiales_create')"
                        label="Agregar" icon="pi pi-plus" class="p-button-success p-ml-2" @click="openNew"/>
              </div>
            </div>
          </template>

          <Column field="id" header="Código" :sortable="true"></Column>
          <Column field="name" header="Nombre" :sortable="true"></Column>
          <Column field="city.name" header="Ciudad" :sortable="true"></Column>
          <Column field="zone.name" header="Zona" :sortable="true"></Column>
          <Column header="Director">
            <template #body="slotProps">
              <p v-if="slotProps.data.employee"> {{ slotProps.data.employee.name }}</p>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-if="canAccess('filiales_update')"
                      icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                      @click="edit(slotProps.data)"/>
              <Button v-if="canAccess('filiales_delete')"
                      icon="pi pi-trash" class="p-button-rounded p-button-warning"
                      @click="confirmDelete(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog v-if="canAccess('filiales_update') || canAccess('filiales_create')"
                v-model:visible="formDialog" :style="{width: '450px'}" header="Filial" :modal="true"
                class="p-fluid">
            <br>
            <div class="p-field   p-fluid" v-if="currentItem">
                <span class="p-float-label">
                   <InputText id="name" v-model.trim="currentItem.name" required="true" autofocus
                              :class="{'p-invalid': submitted && (!currentItem.name)}"/>
                    <label for="count">Nombre</label>
                </span>
              <small class="p-invalid" v-if="submitted && !currentItem.name">El nombre es requerido</small>
            </div>

            <br>
            <div class="p-field   p-fluid" v-if="currentItem">
              <span class="p-float-label">
                  <AutoComplete id="count" :dropdown="true" :multiple="false" field="name"
                                v-model="currentItem.city" :suggestions="cities"
                                @complete="getCatalogue($event, 'cities', 'name')"
                                :class="{'p-invalid': submitted && (!currentItem.city)}"/>
                  <label for="count">Ciudad</label>
              </span>
              <small class="p-invalid" v-if="submitted && !currentItem.city">La ciudad es requerido</small>
            </div>
            <br>
            <div class="p-field   p-fluid" v-if="currentItem.city">
                <span class="p-float-label">
                    <AutoComplete id="zone" :dropdown="true" :multiple="false" field="name"
                                  v-model="currentItem.zone" :suggestions="zones"
                                  @complete="getCatalogue($event, 'zones', 'name')"
                                  :class="{'p-invalid': submitted && (!currentItem.zone)}"/>
                    <label for="count">Zona</label>
                </span>
            </div>
          <br/>
          <div class="p-field   p-fluid" v-if="currentItem">
              <span class="p-float-label">
                  <AutoComplete id="subsi" :dropdown="true" :multiple="false" field="name"
                                v-model="currentItem.employee" :suggestions="employees"
                                @complete="getCatalogue($event, 'employees', 'name')"/>
                  <label for="subsi">Director</label>
              </span>
          </div>
          <br>
          <div class="p-field   p-fluid" v-if="currentItem">
            <label for="address">Dirección</label><br/>
            <Textarea id="address" v-model="currentItem.address" required="true" autofocus rows="3" :autoResize="true"
                       :class="{'p-invalid': submitted && (!currentItem.address)}"/>
            <small class="p-invalid" v-if="submitted && !currentItem.address">El campo dirección es requerido</small>
          </div>
          <br>
          <div class="p-field   p-fluid" v-if="currentItem">
                  <label for="is_main">Sede Principal</label><br/>
                  <InputSwitch id="is_main" v-model="currentItem.is_main" required="true" autofocus
                              :class="{'p-invalid': submitted && (!currentItem.is_main)}"/>
            <small class="p-invalid" v-if="submitted && !currentItem.is_main">El campo sede principal es requerido</small>
          </div>

          <br>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
          </template>
        </Dialog>

        <Dialog v-if="canAccess('filiales_delete')"
                v-model:visible="confirmDeleteModal" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span v-if="currentItem">Seguro de eliminar registro?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text"
                    @click="confirmDeleteModal = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>

</template>

<script>
import service from '../../service/catalog.service'
import {mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: 'Subsidiary',
  data() {
    return {
      path: 'subsidiaries',
      confirmDeleteModal: false,
      processing: false,
      submitted: false,
      formDialog: false,
      filters: {},
      items: null,
      currentItem: null,
      layout: 'list',
      cities: [],
      employees: [],
      zones: []
    }
  },
  created() {

  },
  mounted() {
    if (this.canAccess('filiales_read')) {
      this.getData()
    }
  },
  methods: {
    saveItem() {
      this.submitted = true;
      if (!this.currentItem.name || this.currentItem.name === '' || !this.currentItem.city) {
        return;
      }
      this.processing = true;
      this.formDialog = false;
      this.currentItem.city_id = this.currentItem.city.id;
      this.currentItem.zone_id = this.currentItem.zone ? this.currentItem.zone.id: null;
      this.currentItem.employee_id = this.currentItem.employee ? this.currentItem.employee.id : undefined;
      delete this.currentItem.zone;
      delete this.currentItem.city;
      delete this.currentItem.employee;
      service.save(this.path, this.currentItem, this.currentItem ? this.currentItem.id : undefined).then(() => {
        this.getData();
        this.$toast.add({
          severity: 'success',
          summary: 'Registro guardado',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });

      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.submitted = false;
        this.processing = false;
      });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    openNew() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = true;
    },
    getData() {
      this.processing = true;
      service.get(this.path).then(x => {
        this.items = x.data;
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'se a producido un error',
          life: this.$utils.toastLifeTime()
        });
      }).finally(() => {
        this.processing = false;
      })
    },
    confirmDelete(data) {
      this.currentItem = data;
      this.confirmDeleteModal = true;
    },
    edit(item) {
      this.currentItem = item;
      this.submitted = false;
      this.formDialog = true;

    },
    deleteRow() {
      this.processing = true;
      this.confirmDeleteModal = false;
      service.delete(this.path, this.currentItem.id).then(() => {
        this.currentItem = {};
        this.$toast.add({
          severity: 'success',
          summary: 'Registro eliminado',
          detail: 'Se eliminó correctamente',
          life: this.$utils.toastLifeTime()
        });

        this.getData();
      }).finally(() => {
        this.processing = false;
      });
    },
    getCatalogue(event, route, field) {
      service.getCatalog(event, route+'?country='+this.currentItem?.city?.country_id, field).then(x => {
        this[route] = x;
      });
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
  }
}
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}
</style>
